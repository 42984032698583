.container-room-type-dropdown {
  width: 498px;
  margin-bottom: 24px;
  padding: 24px;
  border: 2px solid #067a04;
  border-radius: 5px;
}

.upper-row-room-type-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.lower-row-room-type-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-type-wrapper-room-type-dropdown {
  position: relative;
}

.select-type-wrapper-room-type-dropdown::after {
  content: '';
  position: absolute;
  top: 30%;
  right: 10px;
  width: 0;
  height: 0;
  border-top:16px solid #067a04;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.select-type-wrapper-room-type-dropdown:hover {
  top: 1px;
  left: 1px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.select-type-room-type-dropdown {
  /* 標準の下矢印を削除 */
  -webkit-appearance: none;/* ベンダープレフィックス(Google Chrome、Safari用) */
  -moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
  appearance: none;

  position: relative;
  height: 40px;
  width: 310px;
  text-align-last: center;
  cursor: pointer;
  border: 2px solid #067a04;
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  color: #035201;
  font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.2em;
}

.lower-row-room-type-dropdown {
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 1fr 3fr 1fr 3fr;
}

.select-size-label-room-type-dropdown {
  height: 35px;
  width: 100%;
  background-color: #067A04;
  color: white;
  font-weight: 700;
  font-size:  1.2rem;
  text-align: center;
  line-height: 35px;
  letter-spacing: 1.6px;
}

.green-border-room-type-dropdown {
  position: relative;
  height: 35px;
  width: 100%;
}

.green-border-room-type-dropdown::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #067A04;
  transform: translateY(-50%);
}

.select-size-wrapper-room-type-dropdown {
  position: relative;
  width: 100%;
}

.select-size-wrapper-room-type-dropdown::after {
  content: '';
  position: absolute;
  top: 30%;
  right: 5px;
  width: 0;
  height: 0;
  border-top:16px solid #067a04;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.select-size-wrapper-room-type-dropdown:hover {
  top: 1px;
  left: 1px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.select-size-room-type-dropdown {
  /* 標準の下矢印を削除 */
  -webkit-appearance: none;/* ベンダープレフィックス(Google Chrome、Safari用) */
  -moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
  appearance: none;

  position: relative;
  display: block;
  height: 38px;
  width: 100%;
  padding-left: 10px;
  cursor: pointer;
  text-align: start;
  border: 2px solid #067a04;
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  color: #035201;
  font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
}

.inactive-select-size-wrapper-room-type-dropdown {
  position: relative;
  width: 100%;
}

.inactive-select-size-wrapper-room-type-dropdown::after {
  content: '';
  position: absolute;
  top: 30%;
  right: 5px;
  width: 0;
  height: 0;
  border-top:16px solid #aaa;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.inactive-select-size-room-type-dropdown {
  -webkit-appearance: none;/* ベンダープレフィックス(Google Chrome、Safari用) */
  -moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
  appearance: none;

  position: relative;
  display: block;
  height: 35px;
  width: 100%;
  padding-left: 10px;
  text-align: start;
  border: 2px solid #808080;
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  color: #808080;
  font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
}

.multiply-sign-room-type-dropdown {
  position: relative;
  display: inline-block;
  width: 20px;  /* バッテンの幅 */
  height: 20px; /* バッテンの高さ */
}

.multiply-sign-room-type-dropdown::before,
.multiply-sign-room-type-dropdown::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 110%;
  width: 5px; /* 線の太さ */
  height: 150%; /* 線の長さ */
  background-color: #067a04;
}

.multiply-sign-room-type-dropdown::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.multiply-sign-room-type-dropdown::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}


