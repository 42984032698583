.container-message {
  max-width: 264px;
  width: 100%;
  margin: 100px auto;
  margin-right: 30px;
  margin-bottom: 40px;
  padding: 40px;
  border: 1px solid #067A04;
  border-radius: 8px;
  background-color: #E8FDE8;
}

.contents-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.image-container-message {
  width: 200px;
  height: 200px;
  margin: auto;
  padding-bottom: 40px;
  flex-shrink: 0;
}

.image-message {
  width: 100%;
  height: 100%;
}

.message-container-message {
  position: relative; /* 吹き出しを追加するためのposition指定 */
  width: 230px;
  margin: auto;
  padding: 0 20px;
  background-color: #fff;
}

/* 吹き出しの三角形 */
.message-container-message::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 50px solid transparent; /* 三角形の左側 */
  border-right: 50px solid transparent; /* 三角形の右側 */
  border-bottom: 25px solid #fff; /* 三角形の下部 */
  position: absolute;
  top: -25px;
  left: 50%;
  margin-left: -50px; /* 三角形の中心を合わせる */
}

.message {
  margin-bottom: 10px;
  line-height: 1.5;
  text-align: start;
  color: #067A04;
  font-weight: 700;
}

.underline-message {
  text-decoration: underline;
  text-decoration-color: yellow;
  text-decoration-thickness: 4px; /* Adjust thickness as needed */
}