.result-container-result {
  position: relative;
  width: 100%;
  border: 1px solid #067A04;
  margin-top: 20px;
  margin-bottom: 40px;
}

.house-mark-result {
  position: absolute;
  top: -20px;
  left: 12px;
  height: 90px;
  width: auto;
}

.floor-plan-description-wrapper-result {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-bottom: 20px;
  padding: 20px;
  border-bottom: 2px solid #067A04;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.floor-plan-title-container-result {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  width: 88%;
  text-align: start;
}

.for-icon-result {
  height: 75px;
  width: auto;
  margin-right: 10px;
}

.floor-plan-title-result {
  margin: 0;
  font-family: 'Goldman', 'Verdana', 'Trebuchet MS', 'sans-serif';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 72px;
  letter-spacing: 0.2rem;
  color: #035201;
}

.floor-plan-explanation-container-result {
  display: flex;
  align-items: center;
  width: 88%;
  text-align: start;
}

.point-icon-result {
  height: 75px;
  width: auto;
  margin-right: 10px;
}

.floor-plan-explanation-result {
  margin: 0;
  font-size: 20px;
  font-family: 'Goldman', 'Verdana', 'Trebuchet MS', 'sans-serif';
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.2rem;
  white-space: pre-wrap;
  color: #035201;
}

.floor-plan-images-result {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.floor-plan-image-group-container-result {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.floor-plan-images-result img {
  width: 100%;
  height: auto;
  margin: auto;
}

.floor-number-result {
  margin-top: 40px;
  margin-bottom: 0px;
  margin-left: 23px;
  font-size: 20px;
  font-family: 'Goldman', 'Verdana', 'Trebuchet MS', 'sans-serif';
  background-color: #067A04;
  color: #fafafa;
  width: 80px;
  align-self: center;
}

.result-description {
  font-size: 14px;
  color: #333;
}

.show-all-button-result {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 350px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0;
  cursor: pointer;
  border: none;

  border-radius: 20px;
  background-color: #067a04;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
}

.show-all-button-result:hover {
  top: 1px;
  left: 1px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.show-all-button-text-result {
  color: #fafafa;
  font-size: 20px;
  font-weight: 900;
  font-family: 'Goldman', 'Verdana', 'Trebuchet MS', 'sans-serif';
}

.show-all-open-arrow-result {
  height: 30px;
  width: 30px;
  margin: 0 10px;
  transform: rotate(90deg);
}

.show-all-close-arrow-result {
  height: 30px;
  width: 30px;
  margin: 0 10px;
  transform: rotate(270deg);
}