.container-required-room-selection {
  display: flex;
  width: 100%;
  margin: 30px auto 0 auto;
  padding: 20px 0;
  background-color: #fafafa;
}

.added-rooms-required-room-selection {
  display: flex;
  width: 650px;
  flex-grow: 1;
  flex-direction: column;
  text-align: start;
}

.added-room-required-room-selection {
  display: flex;
  align-items: center;
}

.delete-button-required-room-selection {
  position: relative;
  width: 32px;
  height: 32px;
  margin: 0 10px 0 17px;
  border-radius: 7px;
  background-color: #067a04;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  color: #fff;
  border: none;
  cursor: pointer;
}

.delete-button-required-room-selection:hover {
  top: 1px;
  left: 1px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.delete-button-required-room-selection::before {
  content: '';
  position: absolute;
  width: 19px; /* 横線の長さ */
  height: 2.5px; /* 横線の太さ */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* 中央に配置 */
  background-color: #fff;
}

.buttonContainer {
  display: flex;
  position: relative;
  height: 40px;
  width: 516px;
  padding: 1px 40px 1px 40px;
  margin: 0 10px;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #067a04;
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  color: #035201;
  font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.2em;
}

.buttonContainer:hover {
  top: 1px;
  left: 1px;
  background-color: #e8fde8; 
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
  
.plus-icon-required-room-selection {
  width: 32px;
  height: 32px;
  position: absolute; 
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 7px;
  background-color: #067a04;
}

.plus-icon-required-room-selection::before {
  content: '';
  position: absolute;
  width: 19px; /* 横線の長さ */
  height: 2.5px; /* 横線の太さ */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* 中央に配置 */
  background-color: #fff;
}

.plus-icon-required-room-selection::after {
  content: '';
  position: absolute;
  width: 2.5px; /* 縦線の太さ */
  height: 19px; /* 縦線の長さ */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* 中央に配置 */
  background-color: #fff;
}
