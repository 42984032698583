.header-button {
  height: 60px;
  width: 280px;
  display: flex;
  justify-content: space-around;
  margin: 10px;
  background-color: #067A04;
  border: solid 2px #fff;
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  
  color: #fafafa;
  font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
}

.header-button img {
  height: 45px;
  width: auto;
  margin-right: 10px;
}

@media (max-width: 1430px) {
  .header-button {
    font-size: 20px;
  }
}