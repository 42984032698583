footer {
  height: 280px;
  width: 100%;
  background-color: #067A04;
  text-align: center;
}

.container-footer {
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  padding: 60px 10% 10px 10%;
}

.contents-footer {
  height: 160px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.contents-footer a {
  text-decoration: none;
}

.content-footer {
  margin: 0;
  cursor: pointer;
  color: #fafafa;
  font-size: 20px;
  font-family: 'Goldman', 'Verdana', 'Trebuchet MS', 'sans-serif';
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.2rem;
  white-space: pre-wrap;
}

.copyright-footer {
  color: #fafafa;
}

.footer-logo-footer {
  height: 60px;
  width: auto;
  cursor: pointer;
}