header {
  height: 80px;
  width: 100%;
  align-items: center;
  background-color: #067A04;
}

.container-header {
  max-width: 1440px;
  width:auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding: 0 10%;
}

.logo-container-header {
  position: relative;
}

.header-logo-header {
  height: 60px;
  width: auto;
  cursor: pointer;
}

.beta-header {
  position: absolute;
  top: 48px;
  left: 180px;
  height: 70px;
  width: auto;
}