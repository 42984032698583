.rectangle-wrapper-required-room-preview {
  width: 144px;
  height: 144px;
  position: relative;
  display: flex;
  border: 0.5px solid #067A04;
  border-radius: 5px;
  justify-content: center; /* 水平方向の中央揃え */
  align-items: center; /* 垂直方向の中央揃え */
}

.rectangle-back-required-room-preview {
  border-bottom: #067A04 solid 1px;
  border-right: #067A04 solid 1px;
  background-color: white;
  background-image:
    linear-gradient(to right, #067A04 1px, transparent 1px),
    linear-gradient(to bottom, #067A04 1px, transparent 1px);
  background-size: 20% 20%;
}

.rectangle-front-required-room-preview {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  outline: 2px solid black;
  outline-offset: -1px;
  background-color: rgba(100, 180, 100, 0.3);
  z-index: 2;
}

.top-required-room-preview, .right-required-room-preview, .bottom-required-room-preview, .left-required-room-preview {
  position: absolute;
  white-space: nowrap;
  font-weight: 600;
  font-size: 1.2rem;
  z-index: 3;
}

.top-required-room-preview {
  top: -22px;
}

.right-required-room-preview {
  right: -35px;
  transform: rotate(270deg);
}

.bottom-required-room-preview {
  bottom: -22px;
}

.left-required-room-preview {
  left: -35px;
  transform: rotate(-90deg);
}
