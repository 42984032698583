.container-not-found {
  padding-top: 150px;
  height: 55vh;
}

.title-not-found {
  color: #035201;
  font-size: 40px;
  font-family: 'Goldman', 'Verdana', 'Trebuchet MS', 'sans-serif';
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.2rem;
}