.container-privacy-policy {
  width: 80%;
  margin: 60px auto 140px auto;
}

.title-privacy-policy {
  color: #555;
}

.section-wrapper-privacy-policy {
  margin: 80px 0;
}

.section-title-privacy-policy {
  font-size: 24px;
  color: #333;
  margin-top: 20px;
  border-bottom: 2px solid #82aa88;
  padding-bottom: 5px;
}

.content-text-privacy-policy {
  width: 80%;
  margin: auto;
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.external-link-privacy-policy {
  color: #007bff;
  text-decoration: none;
}

.external-link-privacy-policy:hover {
  text-decoration: underline;
}

.external-link-privacy-policy:visited {
  color: #0056b3;
}