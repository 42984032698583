  .band-step-header {
    position: relative;
    width: 100%;
    height: 72px;

    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    background: #fafafa;
    box-shadow: 2px 4px 1px rgba(0, 0, 0, 0.1);
  }

  .background-sapce-step-header {
    height: 80px;
    width: 80%;
    margin: auto;

    background-color: #E8FDE8;
  }

  .band-step-header::before {
    content: '';
    position: absolute;
    width: 10%;
    height: 72px;
    left: 0px;
    top: 0px;

    background: #067a04;
  }

  .band-step-header::after {
    content: '';
    position: absolute;
    width: 10%;
    height: 72px;
    right: 0;
    top: 0px;
    
    background: #067a04;
  }

  .icon-step-header {
    position: relative;
    margin: auto;
    width: 100px;
  }

 .icon-roof-step-header {
    width: 0;
    height: 0;
    border-left: 50px solid transparent; /* 三角形の左側 */
    border-right: 50px solid transparent; /* 三角形の右側 */
    border-bottom: 25px solid #067a04; /* 三角形の下部 */
    position: absolute;
    top: -40px;
    left: 50%;
    margin-left: -50px; /* 三角形の中心を合わせる */
  }

  .icon-body-step-header {
    width: 80px;
    height: 20px; /* 四角形の高さ */
    background-color: #067a04; /* 四角形の背景色 */
    position: absolute;
    top: -15px;
    left: 10px;
  } 

  .step-number-step-header {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -32px;

    font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    transform: scale(1.2, 1);

    color: #fafafa;
  }

  .description-step-header {
    width: 70%;
    margin: auto;

    font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 72px;
    text-align: center;
    letter-spacing: 0.3em;

    color: #035201;
  }
