.container-affiliate {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  margin-bottom: 40px;
}

.upper-affiliate img {
  margin-bottom: 20px;
  cursor: pointer;
}

.lower-affiliate img {
  cursor: pointer;
}