.container-contact {
  max-width: 600px;
  width: 80%;
  margin: 100px auto 100px auto;
  margin-bottom: 40px;
  padding: 20px;
  padding-bottom: 50px;
  border: 1px solid #067A04;
  border-radius: 8px;
  background-color: #E8FDE8;
}

.shrinked-container-contact {
  max-width: 600px;
  width: 80%;
  margin: 100px auto 100px auto;
  padding: 20px;
  padding-bottom: 0;
  border: 1px solid #067A04;
  border-radius: 8px;
  background-color: #E8FDE8;
}

.title-contact {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #067A04;
}

.description-contact {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
  color: #555;
}

.form-contact {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: auto;
}

.user-name-contact,
.user-email-contact {
  font-size: 14px;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 18px);
}

.message-contact {
  height: 120px;
  font-size: 14px;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 18px);
}

.submit-contact {
  position: relative;
  height: 40px;
  width: 300px;
  margin: 0 auto;
  padding: 0;
  border: none;
  border-radius: 10px;
  background-color: #067a04;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.submit-contact:hover {
  top: 1px;
  left: 1px;
  box-shadow: 1px 1px 1
}

.disabled-submit-contact {
  position: relative;
  height: 40px;
  width: 300px;
  margin: 0 auto;
  padding: 0;
  border: none;
  border-radius: 15px;
  background-color: #ccc;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  cursor: pointer;
}

.validation-message-contact {
  margin-top: 10px;
  text-align: center;
  color: red;
}

.response-message-contact {
  margin-top: 10px;
  text-align: center;
  color: green;
}
