.container-parking-selection {
    display: flex;
    width: 100%;
    margin: 30px auto 0 auto;
    padding: 20px 0;

    background-color: #fafafa;
  }

  .button-container-parking-selection {
    display: flex;
    width: 650px;
    flex-grow: 1;
    flex-direction: row;

    margin-top: 10px;
  }

  .input-label-parking-selection {
    display: block;
    margin: auto 10px;
    width: 120px;
    height: 40px;
    position: relative;

    padding: 0 5px;
    color: #035201;
    text-align: center;
    cursor: pointer;
    border: 2px solid #067a04;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  }

  .input-label-parking-selection:hover {
    top: 1px;
    left: 1px;

    background-color: #e8fde8; 
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }

  .checked-parking-selection + .input-label-parking-selection {
    background-color: #067a04;
    color: #fafafa;
  }

  .button-text-parking-selection {
    height: 40px;
    margin: auto;

    font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: 0.2em;
  }

  .disable-input-label-parking-selection {
    display: block;
    margin: auto 10px;
    width: 120px;
    height: 40px;
    position: relative;

    padding: 0 5px;
    background-color: #ccc;
    /* color: #035201; */
    color: #777;
    text-align: center;
    /* cursor: pointer; */
    /* border: 2px solid #067a04; */
    border: 2px solid #777;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  }