  .container-selection-title-label {
    flex-grow: 1;
  }

  .content-selection-title-label {
    width: 140px;
    height: 50px;

    border: solid 1px #067a04;
    border-left: solid 17px #067a04;
    font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    letter-spacing: 0.3em;

    color: #035201;
  }