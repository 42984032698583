  .App {
    min-width: 1000px;
    margin: auto;
    text-align: center;

    background: #fafafa;
  }

  .container-main {
    max-width: 1440px;
    margin: auto;
  }

  .step-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    
    align-items: center;
    margin: auto;
    padding-bottom: 30px;

    background-color: #E8FDE8;
  }

  .step4-container {
    background-color: #fafafa;
  }

  .grid-size-annotation-wrapper {
    width: 100%;
    height: 60px;
    background-color: #fafafa;
  }

  .grid-size-annotation-text {
    margin: 0;
    color: #035201;
    font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
    font-weight: 700;
    font-size: 18px;
    line-height: 60px;
  }

  input[type=radio] {
    display: none;
  }

  /* Header */
  .header-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .header-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  
  .header-description {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 10px;
    border: '1px solid blue';
  }
  
  .select-area-yx {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .select-area-y {
    margin-left: 10px;
    margin-right: 10px;
    width: 120px;
    display: inline-block;
  }
  
  .select-area-x {
    margin-left: 10px;
    margin-right: 10px;
    width: 120px;
    height: 30px;
    display: inline-block;
  }
  
  
  
  /* STEP1 select area */
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    /* height: 100vh; */
    /* Set the height of the container as needed */
  }

  .neigber-selector-label-container {
    text-align: left;
    margin: 0 10px;
  }

  .neigber-selector-label {
    display: inline-block;
    color: #035201;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
  }
  
  .top {
    order: 1;
    margin: 10px 0;
  }
  
  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;
    margin-top: 0;
    margin-bottom: 0;
  }

  .neigber-selector {
    width: 130px;
    margin: 0 10px;
  }

  .left-neigber-selector {
    height: 80px;
  }

  .right-neigber-selector {
    height: 80px;
  }
  
  .bottom {
    order: 3;
    margin-top: 0;
  }
  
  .neighbor-select {
    margin-left: 10px;
    margin-right: 10px
  }
  
  /* STEP 2 */
  .toggle-selector {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  /* solve */
  .example-select {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  /* select area */
  .area-grid {
    margin-top: 10px;
  }
  
  .area-row {
    display: flex;
  }
  
  .area-cell {
    align-items: center;
    background: #fff;
    border: 1px solid #999;
    float: left;
    font-size: 12px;
    font-weight: bold;
    line-height: 42px;
    height: 40px;
    width: 40px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
  }
  
  .selected-cell {
    background: rgb(121, 188, 246);
  }
  
  .non-selected-cell {
    background: #fff;
  }
  
  /* pull down */
  .select-y {
    margin-left: 10px;
    margin-right: 10px;
    width: 80px;
    display: inline-block;
  }
  
  .select-x {
    margin-left: 10px;
    margin-right: 10px;
    width: 80px;
    display: inline-block;
  }

  .solve-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 350px;
    margin-top: 40px;
    margin-bottom: 50px;
    padding: 0;
    cursor: pointer;
    border: none;

    border-radius: 20px;
    background-color: #067a04;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
  }

  .solve-button:hover {
    top: 1px;
    left: 1px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .disabled-solve-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 350px;
    margin-top: 40px;
    margin-bottom: 50px;
    padding: 0;
    cursor: pointer;
    border: none;

    border-radius: 20px;
    background-color: #ccc;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
  }

  .solve-button-text {
    width: 220px;
    color: #fafafa;
    font-size: 20px;
    font-weight: 900;
    font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
  }

  .disabled-solve-button-text {
    width: 220px;
    color: #777;
    font-size: 20px;
    font-weight: 900;
    font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
  }

  .auto-create-arrow {
    height: 30px;
    width: 30px;
    margin: 0 10px;
  }

  .communication-wrapper {
    display: flex;
    width: 80%;
    margin: auto;
  }