.container-floor-selection {
    display: flex;
    width: 100%;
    margin: 30px auto 0 auto;
    padding: 20px 0;

    background-color: #fafafa;
  }

  .button-container-floor-selection {
    display: flex;
    width: 650px;
    flex-grow: 1;

    flex-direction: row;
  }

  .input-label-floor-selection {
    display: block;
    width: 150px;
    height: 170px;
    position: relative;
    
    margin: 0 20px;
    padding: 0 5px;
    color: #035201;
    text-align: center;
    cursor: pointer;
    border: 2px solid #067a04;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  }

  .disable-input-label-floor-selection {
    display: block;
    width: 150px;
    height: 170px;
    position: relative;
    
    margin: 0 20px;
    padding: 0 5px;
    color: #777;
    text-align: center;
    background-color: #ccc;
    /* cursor: pointer; */
    border: 2px solid #777;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  }

  .input-label-floor-selection:hover {
    top: 1px;
    left: 1px;

    background-color: #e8fde8; 
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }

  .checked-floor-selection + .input-label-floor-selection {
    background-color: #067a04;
    color: #fafafa;
  }

  .button-image-floor-selection {
    display: block;
    height: 100px;
    width: 130px;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 36px;
    margin: auto;
  }

  .button-text-floor-selection {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    margin: auto;

    font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2em;
  }