.tips-container-tips {
  width: 100%;
  margin: 40px 0;
}

.tips-wrapper-tips {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 100%;
  vertical-align: middle;
  border-top: double 5px #067A04;
  border-bottom: double 5px #067A04;
}

.tips-wrapper-tips img {
  position: absolute;
  top: -45px;
  right: 0;
  left: 0;
  height: auto;
  width: 120px;
  margin: auto;
}

.tips-text-tips {
  margin: 0;
  font-size: 20px;
  font-family: 'Goldman',  'Verdana', 'Trebuchet MS', 'sans-serif';
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.2rem;
  white-space: pre-wrap;
  color: #035201;
}